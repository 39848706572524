<template>
    <div class="home">
        <x-header back>删除的实验</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <!-- <div class="search_warp">
                <el-row class="list-control_warp">
                    <el-col :span="24">
                        <el-col :span="6">
                            <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入实验名称">
                                <template slot="suffix">
                                    <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                                </template>
                            </el-input>
                        </el-col>
                        &nbsp;
                        <div style="display: flex; justify-content: flex-end;">
                            <el-button style="" size="medium" type="primary" @click="openDialog" icon="base-icon-plus">
                                    <span>新建实验</span>
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div> -->
            <div style="margin-bottom:10px;">
                <el-button icon="el-icon-refresh-right" @click="cancelBatchDelete"> 批量还原</el-button>
            </div>
            <x-card>
                <el-table
                    :data="experimentList"
                    stripe
                    ref="reviewExperiemnt"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="experiment_id"
                        label="实验编号">
                        <template slot-scope="scope">
                            <el-link @click="$router.push('/manager/experiment/detail/'+scope.row.uuid+'/1')" :underline="false">{{scope.row.experiment_id}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="subject"
                        label="实验名称">
                    </el-table-column>
                    <el-table-column
                        label="所属项目">
                        <template slot-scope="scope">
                               <span class="">{{experimentGroupIdNameMap[scope.row.group_id]}}</span> 
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="250"
                        label="属性">
                        <template slot-scope="scope">
                            <div style="display: flex; flex-direction: column;">
                                <span class="">创建时间：{{scope.row.create_time}}</span>
                                <span class="" v-if="scope.row.update_time">修改时间：{{scope.row.update_time}}</span>
                                <span class="">创建人：{{scope.row.user.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="100"
                        label="实验状态">
                        <template slot-scope="scope">
                            <span class="group_operate">{{scope.row.status=='0'?"待复核":scope.row.status=='1'?'复核通过':scope.row.status=='2'?'复核拒绝':scope.row.status=='3'?'待提交':''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="100"
                        label="操作">
                        <template slot-scope="scope">
							<el-button  class="group_operate" size="mini" @click="cancelDelete(scope)" type="success">还原</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="listExperiment"
                        :hide-on-single-page="total<pageSize"
                        background
                        layout="total,prev, pager, next"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
    </div>
</template>
<script>
    import {GetDeleteList,CancelDelete} from "@/service/experiment";
        import {GetMyProjectList} from "@/service/user"

    export default {
        name:'ExperimentDelete',
        data(){
            return {
                loading:false,
                saveLoading:false,
                savePermissionLoading:false,
                page:1,
                total:0,
                pageSize:10,
                searchText:"",
                experimentList:[],
                selectExperiment:[],
                experimentGroupIdNameMap:{},
            }
        },
		created(){
            this.status = this.$route.params.status || '';
            this.listExperiment();
                        this.getMyProjectList();

        },
        watch:{
			// '$route.params.status':{
			// 	handler:function (newVal){
            //         if(newVal){
            //             this.status = newVal
            //             this.listExperiment();
            //         }
			// 	},
			// 	immediate:true
			// }
		},
        methods:{
            search() {
				this.page = 1;
				this.listExperiment();
			},
             getMyProjectList(){
                GetMyProjectList().then(e => {
                    let data = this.APIParse(e);
                    data.forEach(item => {
                      this.$set(this.experimentGroupIdNameMap,item.id,item.groupName)
                    })
                })
            },
            goExperimentEdit(){
                this.$router.push('/manager/experiment/edit/'+this.form.note+'/'+this.form.formTemplate+'/1/0')
            },
            listExperiment(){
                this.loading = true;
                GetDeleteList(this.page,this.pageSize).then(e => {
                    let data = this.APIParse(e);
                    this.experimentList = data?.hits.map(item => {
                        return item._source;
                    });
                    this.total = data.total || 0;
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(e => {
                    this.loading = false;
                })
            },
            handleSelectionChange(val){
                this.selectExperiment = val;
            },
            cancelDelete(scope){
                this.$confirm('确定还原这个实验吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    CancelDelete(scope.row.uuid).then(e => {
                        let data = this.APIParse(e);
                        if(data){
                            this.$message.success('还原实验成功')
                            this.listExperiment();
                        }else{
                            this.$message.error('还原实验失败，请重试')
                        }
                    }).catch(ex => {
                        this.$message.error('还原实验失败，请重试')
                    })
                }).catch(() => {  
                });
            },
            cancelBatchDelete(){
                if(this.selectExperiment.length==0){
                    this.$message.warning("请选择实验！")
                    return
                }
                this.$confirm('确定还原所选的这些实验吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    CancelDelete(this.selectExperiment.map(e => {return e.uuid}).join(",")).then(e => {
                        let data = this.APIParse(e);
                        if(data){
                            this.$message.success('还原实验成功')
                            this.listExperiment();
                        }else{
                            this.$message.error('还原实验失败，请重试')
                        }
                    }).catch(ex => {
                        this.$message.error('还原实验失败，请重试')
                    })
                }).catch(() => {  
                });
            },
            changeShow(){
                this.page = 1
                this.listExperiment();
            },
        }
    }
</script>
<style lang="scss" scoped>
    .group_operate+.group_operate{
        margin-left: 15px;
    }
    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
    
    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
    .page-warp{
        text-align: center;
        margin-top: 20px;
    }
    .mySign {
        border: 1px dashed #000;
    }
</style>